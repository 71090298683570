.signup-page-wrapper {
    text-align: center;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    height: 100vh;
    top: 0;
    margin: 0;
    padding: 0;
    background: rgb(214, 232, 255);
    background-size:cover;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    z-index: 1;
}

.idontcare-p{

}

.logout-button {
    border-radius: 5px;
    color: white;
    background-color: #4CAF50;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;

}