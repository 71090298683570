/* font something nice
*/

/* body {
    justify-content: center;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    background-color: #fff;
} */

.album-page-wrapper {
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    min-height: 100vh;
    height: 100%;
    margin: 0;
    padding: 0;
    background: url("https://images.pixexid.com/a-vertical-array-of-christmas-wreaths-each-uniquely-decorated-with-ribbons-pin-mbi7txw3.jpeg") center center fixed;
    background-size:cover;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: calc(10px + 2vmin);
    z-index: 1;
    align-items: center;
}

.album-page-wrapper::before {
    max-height: 100vh;
    content : "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background:inherit center center fixed;
    background-size: cover;
    filter: blur(10px); /* Adjust the blur intensity as needed */
    z-index: -1; /* Place the blurred content behind the original background */
}

.bulletin-board {
    min-height: 80vh;
    max-width: 60vw;
    margin: 50px auto;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    background: url(../img/backdrop-background-blank-board.jpg) repeat ;
    border: 2px  #ccc; /* Decorative dashed border */
}

.pin {
    position: absolute;
    top: 5%; /* Adjust the initial position as needed */
    left: 50%;
    transform: translateX(-50%);
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
    }
    
.polaroid {
    position: relative;
    margin: 10px auto 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 300px; /* Set the maximum width for the polaroid */
    width: 100%;
    cursor: default;
    transition: transform 0.3s ease-in-out;
    border-radius: 8px;
    z-index: 10;
    background: #fff;
    padding: 1vw 1vw 2vh 1vw;
}

.polaroid:hover {
    transform: scale(1.1);
}

.polaroid img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    cursor: pointer;
}

.polaroid::before {
    content: inherit;
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    /* border: 5px solid #fff; */
    border-radius: 8px;
}

.description {
    display: flex;
    max-width: 50vw;
    margin: auto;
    text-align: center;
    text-wrap: wrap;
    overflow-wrap: normal break-word;
    margin-top: 10px;
    border-radius: 8px;
    font-size: calc(5px + 2vmin);
}

.modal-description {
    display: flex;
    min-height: 5vh;
    max-width: 50%;
    /* margin: auto; */
    text-align: center;
    text-wrap: wrap;
    overflow-wrap: break-word;
    border-radius: 10px;
    font-size: calc(10px + 1vmin);
}

/* Modal styles */
.modal {
    display: none;
    position: fixed;
    top: 0;
    margin: auto;
    max-width: 100%;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    max-width: 100vw;
    max-height: 80vh;
    width: fit-content;
    height: auto;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    position: relative;
    z-index: 1001;
}

.modal img {
    max-width: 100%; /* Ensure the image doesn't exceed the viewport width */
    max-height: 60vh; /* Ensure the image doesn't exceed the viewport height */
    height: auto;
    width: auto;
    border-radius: 10px;
}

.modal h2 {
    display: flex;
    margin: 0;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: calc(10px + 3vmin);
    height: calc(15px + 3vmin);
}

.name-and-edit-flex {
    height: calc(15px + 3vmin);
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
}

.close-btn {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

@media screen and (max-width: 600px) {
    .polaroid {
    max-width: calc(48% - 30px); /* Ensure at least 2 in a row with margin */
    }
}

@media screen and (min-width: 601px) and (max-width: 1024px) {
    .polaroid {
    max-width: calc(48% - 30px); /* Ensure at least 2 in a row with margin */
    }
}
@media screen and (min-width: 1024px) and (max-width: 2310px) {
    .polaroid {
    max-width: calc(30% - 30px); /* Ensure at least 2 in a row with margin */
    }
}

.polaroid iframe {
    width: 100%;
    height: auto;
    border-radius: 10px;
    cursor: pointer;
}

.top-bar {
    top:0;
    right: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 10vh;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    margin-bottom: 20px;
    background: #ececec;
    position:sticky;
    z-index: 100;
}

.nav-go-back {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    left: 0;
}
.nav-go-back a{
    margin-left: 5vw;
    text-decoration:none;
    color: black;
}

.album-cover-photo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: 0 3vw 0 3vw;
}
.album-cover-photo img{
    width:auto;
    height: 8vh;
    width: auto;
    border-radius: 50%;
    cursor: pointer;
    margin: 1vh 0px 1vh 0px;
}  

.add-image-buttons {
    justify-content:center;
    align-items:center;
    display:flex;
    flex-direction:row;
    justify-content: center;
    margin: 0 20vw 2vh 20vw;
}

.add-image-buttons-drive {
    align-items: center;
    justify-items: center;
    text-align: center;
    width: 20vw;
    height: 10vh;
    border-radius: 10px;
    border: none;
    background: #fff;
    cursor: pointer;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
    margin: 10px;
    font-size: calc(10px + 1vmin);
    font-family: inherit;
}

/* .add-image-buttons button {
    width: 20vw;
    height: 10vh;
    border-radius: 10px;
    border: none;
    background: #fff;
    cursor: pointer;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
    margin: 10px;
    font-size: calc(10px + 1vmin);
    font-family: inherit;
} */

.add-image-buttons button:hover {
    transform: scale(1.1);
}

.add-image-buttons input {
    display: contents;
    border-radius: 10px;
    width: 20vw;
    height: 10vh;
}

.file-input-label {
    display:flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 20vw;
    height: 10vh;
    border-radius: 10px;
    border: none;
    background: #fff;
    cursor: pointer;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
    margin: 10px;
    font-size: calc(10px + 1vmin);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.file-input-label label {
    display: contents;
    font-size: inherit;
}

.file-input-label:hover {
    transform: scale(1.1);
}

.album-info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-content: flex-start;
    width: 80vw;
}

.album-name-and-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 50vw;
    
}
.album-name-and-description p {
    margin: 0 0 0 0;
}

.album-settings {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    right: 10vw;
    margin: 0 5% 0 10%;
}

@media screen and (max-height: 640px) {
    .top-bar {
        top:0;
        right: 0;
        left: 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        height: calc(10vh + 30px);
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        margin-bottom: 20px;
        background: #fff;
        position:sticky;
        z-index: 100;
    }
    .album-cover-photo img{
        width:auto;
        height: calc(8vh + 30px);
        width: auto;
        border-radius: 50%;
        cursor: pointer;
        margin: 1vh 0px 1vh 0px;
    }
}