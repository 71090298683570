.albums-hub-wrapper {
    align-items: center;
    align-content: center;
    text-align: center;
    min-height: 100vh;
    height: 100%;
    margin: 0;
    padding: 0;
    background: rgb(214, 232, 255);
    background-size:cover;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    z-index: 1;
}

.albums-hub-wrapper a{
    text-decoration: none;
    color: black;
}

.album-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 400px;
    margin: 20px;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.6);
    background: grey;
    border: 2px  #ccc; /* Decorative dashed border */
}

.album-cards-list {
    align-content: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content:center;
    align-items: center;
    border: 2px  #ccc; /* Decorative dashed border */
}

.add-album-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 400px;
    margin: 20px;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.6);
    background: rgb(180, 180, 180);
    border: 2px  #ccc; /* Decorative dashed border */
    cursor: pointer;
}

.add-album-popup {
    content: "";
    display: none;
}
.add-album-popup.visible {
    display:block;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    top: 0;
    left: 0;
    z-index: 1;
}

.add-album-popup-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 50%;
    margin: auto;
    margin-top: 20vh;
}

.add-album-popup-content input {
    width: 50vw;
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.add-album-popup-content button {
    width: 100%;
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #5ba6ac;
    color: white;
}

.add-album-popup-content button:hover {
    background-color: #2672b9;
}
